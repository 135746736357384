//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled} from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import ArrowDown from "@material-ui/icons/KeyboardArrowDownOutlined";
import Check from "@material-ui/icons/Check";
import ArrowRight from "@material-ui/icons/ArrowRight";
import BoltIcon from '@material-ui/icons/OfflineBoltOutlined';
import AddShopping from '@material-ui/icons/AddShoppingCartOutlined';
import INbox from '@material-ui/icons/InboxOutlined';
import History from '@material-ui/icons/HistoryOutlined';
import Planet from '@material-ui/icons/PublicOutlined';
import Truck from '@material-ui/icons/LocalShippingOutlined';
import ArrowForward from '@material-ui/icons/KeyboardArrowRight';
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import {buttonIocn, callIcon, Done, Downarrow, InstantIcon, lead, location, mainIcon, parcel, Regional, transport, view, zoom} from "./assets"

export const ProductBlock = styled("div")(({ theme }) => ({
  "& .tableEye":{
    width:"12px"
  },
  "& .viewText":{
    fontWeight:400, 
    fontSize:"12px",
    display:"flex",
    cursor:"pointer"
  },
  "& .variationTh":{
    whiteSpace:"nowrap",
    padding:"15px"
  },
  "& .mainPhoto-container":{
    position:"relative",
    width:"386px",
    "@media (max-width:1200px)": {
      width:"100%",
    },
    "@media (max-width:900px)": {
      width:"90vw",
      margin:"0 auto",
      display:"flex",
      justifyContent:"center"
    },
  },
  "& .mainPhoto__zoom":{
    position:"absolute",
    right:"11px",
    bottom:'11px',
    cursor:"pointer"
  },
  "& .currentWay":{
    fontWeight:400,
  },
  "& .globalContainer":{
    padding: "0px 90px",
    "@media (max-width:1200px)": {
      padding: "0px 20px",
    },
    "@media (max-width:900px)": {
      padding: "0px 10px",
    },
  },
  "& .navMenu":{
    "@media (max-width:1200px)": {
      marginLeft:"0px",
      padding:"0px 20px"
    },
    "@media (max-width:900px)": {
      marginLeft:"0px",
      padding:"0px 10px"
    },
  },
  "& .notMainPhoto-container":{
    width:'386px',
    "@media (max-width:1200px)": {
      width: "100%"
    },
    "@media (max-width:900px)": {
      width:'386px'
    },
  },
  "& .notMainPhoto":{
    width: "90px",
    cursor:"pointer",
    "@media (max-width:1200px)": {
      width: "60px"
    },
    "@media (max-width:900px)": {
      width: "70px"
    },
  },
  "& .MuiGrid-grid-md-2":{
    flexBasis:"auto"
  },
  "& .documentsTable":{
    width:"386px",
    "@media (max-width:1200px)": {
      width:"90%",
    },
    "@media (max-width:900px)": {
      width:"80vw",
    },
  },
  "& .featureTableText":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#9095A1",
    lineHeight:'22px'
  },
  "& .Variations__mainText":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 700,
    color:"#323743",
    lineHeight:'22px'
  },
  "& .Variations__text":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
    color:"#171A1F",
    lineHeight:"26px"
  },
  "& .MuiCheckbox-root":{
    color: "#565D6D",
    '&.Mui-checked': {
      color: "#E55C00",
    },
  },
  "& .brandTableBody":{
    display: 'block',
    height:"160px",
    overflowY:"scroll",
    scrollbarColor:"#BDC1CA transparent",
    scrollbarWidth:"thin",
    scrollbarHeight : "255px"
  },
  "& .brandTableBody::-webkit-scrollbar-track":{
    backgroundColor:"blue"
  },
  "& .brandTableHead":{
    display:'block'
  },
  "& .tableBrand-Brand":{
    width:"90px",
    padding:"15px",
    textAlign:"center"
  },
  "& .tableBrand-Name":{
    width:"150px",
    padding:"15px",
    textAlign:"center",
    whiteSpace:"nowrap"
  },
  "& .tableBrand-SKU":{
    width:"115px",
    textAlign:"center"
  },
  "& .tableBrand-arrow":{
    width:"50px",
    textAlign:"center",
    cursor:"pointer"
  },
  "& .tableBrand-checkbox":{
    width:"45px",
    textAlign:"center"
  },
  "& .quote":{
    marginLeft:"50px",
    "@media (max-width:1200px)": {
      marginLeft:"5px"
    },
    "@media (max-width:900px)": {
      marginTop:"20px",
      marginLeft:"0px"
    },
  },
  "& .request":{
    marginLeft:"50px",
    "@media (max-width:1200px)": {
      marginLeft:"5px"
    },
    "@media (max-width:900px)": {
      marginLeft:"0px"
    },
  },
  "& .requestSample":{
    fontSize: "16px",
    fontWeight: 700,
    color:"#323743",
    fontFamily: 'Epilogue, sans-serif',
    lineHeight : "38px"
  },
  "& .noteText":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "11px",
    fontWeight: 400,
    color:"#9095A1",
    lineHeight:"26px",
    fontStyle:"italic"
  },
  "& .noteTextMain":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "11px",
    fontWeight: 400,
    color:"#323743",
    lineHeight:"26px",
    fontStyle:"normal",
  },
  "& .mainTextDescription":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#171A1F",
    lineHeight:"24px",
  },
  "& .seccondaryTextDescription":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#565D6D",
    lineHeight:"24px",
  },
  "& .specificationMainText":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "24px",
    fontWeight: 600,
    color:"#181A1E",
    lineHeight:"30px",
    marginBottom:"24px",
    marginTop : "25px"
  },
  "& .specTableRow:nth-child(odd)":{
    backgroundColor:"#FAFAFB"
  },
  "& .certTable":{
    width:"100%",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 500,
    lineHeight:"20px",
    color:"#1E293B",
    marginBottom:"20px",
    border:"1px solid #DEDFE3",
    overflow:"hidden",
    borderRadius:"8px"
  },
  "& .specTableRow__td-text":{
    width:"90%",
    padding:"20px"
  },
  "& .gridTableSpec":{
    "@media (min-width:900px)": {
      maxWidth:"48%",
      flexBasis:"48%"
    },
  },
  "& .specTableRow__td-eye":{
    width:"90%",
    padding:"20px"
  },
  "& .certTableRow:nth-child(odd)":{
    backgroundColor:"#FAFAFB"
  },
  "& .certificationEye":{
    cursor:"pointer"
  },
  "& .quantityNumberArrow":{
    cursor:"pointer"
  }
}))
const theme = createTheme({
  cursor:"default",
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '0px',
      },
    },
  },
});


const dummySpec = [
  {key: "Brand", value: "New Age"},
  {key: "Brand Collection Name", value: "Professional Router"},
  {key: "Brand SKU", value: "Lorem"},
  {key: "Brand SKU Number", value: "Lorem"},
  {key: "ISIN", value: "Lorem"},
  {key: "IS Specifications", value: "IS-269-2015"},
  {key: "Standard Packaging", value: "Lorem"},
  {key: "IS Specifications", value: "IS-269-2015"},
  {key: "Standard Packaging", value: "Lorem"},
  {key: "Master Packaging", value: "Lorem"},
]

const dummySpec1 = [
  {key: "Colour", value: "Power Tools"},
  {key: "Material", value: "Professional Router"},
  {key: "Mounting Type", value: "Flanged"},
  {key: "Inlet Type", value: "Product Type"},
  {key: "Finish", value: "Natural Polish"},
  {key: "Number of Doors", value: "3"},
  {key: "No of Lines", value: "2 Way"},
  {key: "Outlet Type", value: "Lorem"},
  {key: "Body", value: "Gunmetal"},
  {key: "Foam Induction Type", value: "Self Inducting"},
]

const Physical = [
  {key: "Length in inch", value: "30 inch"},
  {key: "Length in mm", value: "50 mm"},
  {key: "Width in inch", value: "30 inch"},
  {key: "Width in mm", value: "50 mm"},
  {key: "Height in inch", value: "30 inch"},
  {key: "Height in mm", value: "50 mm"},
  {key: "Thickness", value: "30 inch"},
  {key: "Bore Size in inch", value: "30 inch"},
  {key: "Bore Size in mm", value: "50 mm"},
  {key: "Diameter in inch", value: "30 inch"},
  {key: "Diameter in mm", value: "50 mm"},
  {key: "Monitor Barrel Size in inch", value: "30 inch"},
  {key: "Monitor Barrel Size in mm", value: "50 mm"},
  {key: "Nozzle Tip Size", value: "30 inch"},
  {key: "Inlet Size", value: "50 mm"},

]
const Mechanical = [
  {key: "Bursting Pressure in bar", value: "30 bar"},
  {key: "Bursting Pressure in psi", value: "50 psi"},
  {key: "Working Pressure in bar", value: "30 bar"},
  {key: "Working Pressure in psi", value: "50 psi"},
  {key: "Operating Pressure in bar", value: "30 bar"},
  {key: "Operating Pressure in psi", value: "50 psi"},
  {key: "Nozzle Pressure in bar", value: "30 bar"},
  {key: "Nozzle Pressure in psi", value: "50 psi"},
  {key: "Hydrotest Pressure in bar", value: "30 bar"},
  {key: "Hydrotest Pressure in psi", value: "50 psi"},
  {key: "Body Test in psi", value: "30 bar"},
  {key: "Body Test in psi", value: "50 psi"},
  {key: "Seat Test in bar", value: "30 bar"},
  {key: "Seat Test in psi", value: "50 psi"},
]

const dummyCertificate = ["Federal Communications Commission (FCC)", "Industry Canada (IC)",
"RoHS Compliance", "WEEE Compliance", "UL Listing (USA)", "CE Marking (Europe)",
"Energy Star (USA)", "ETL Listed Mark (USA)", "ISO 9001 (Quality Management)"
]
const dummyBrandTable = [
  {
    brand:"Finolex",
    name:"ELBOW 90° PLAIN",
    sku:"2025000"
  },
  {
    brand:"Paras",
    name:"Elbow 90 Plain",
    sku:"675427846"
  },
  {
    brand:"Astral",
    name:"Elbow 90 Plain",
    sku:"675427846"
  },
  {
    brand:"Finolex",
    name:"ELBOW 100 PLAIN",
    sku:"2025800"
  },
  {
    brand:"Paraska",
    name:"Elbow 98 Plain",
    sku:"675427246"
  },
  {
    brand:"Astrall",
    name:"Elbow 92 Plain",
    sku:"675427841"
  }
]
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.dummyImg = [dummyProduct, dummyProduct, dummyProduct, dummyProduct];
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPostData();
  }
  buttons = [
    { id: 1, label: 'Description' },
    { id: 2, label: 'Specifications' },
    { id: 3, label: 'Ways of Application' },
    { id: 4, label: 'Use' },
    { id: 5, label: 'Certifications' },
    { id: 6, label: 'Standards' },
    { id: 7, label: 'Quality Tests' },
    { id: 8, label: 'New Button' }
  ];
  getButtonStyle(buttonId:number) {
    return this.state.pageDescription === buttonId 
      ? webStyle.descriptionButton__active 
      : webStyle.descriptionButton;
  }
  getRowBackgroundColor(index) {
    return index % 2 ? 'white' : '#FAFAFB';
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <ProductBlock>
        <Navbar history={this.props.history} {...this.props}/>
        <Box>
        <Box style={{display:'flex' , padding:'15px 48px 34px 55px' , gap:'15px 30px', justifyContent:'space-between', flexWrap:'wrap' }} >
        <Grid container direction="row" alignItems="center" style={webStyle.navMenu}  justifyContent="flex-start">
          <Grid item style={webStyle.navMenuItem} id="navigation"  xs={4} sm={4} md={2}>
            <span style={{...webStyle.topnav}}>Home</span>   
            <ArrowForward style={{...webStyle.arrowforward}} />          
          </Grid>
          
          <Grid item style={webStyle.navMenuItem} id="navigation" xs={4} sm={4} md={2}>
            <span style={{...webStyle.topnav}}>Plumbing</span>  
            <ArrowForward style={{...webStyle.arrowforward}} />          
          </Grid>  
               
          <Grid item style={webStyle.navMenuItem} id="navigation" xs={4} sm={4} md={2}>
            <span style={{...webStyle.topnav}}>Internal Plumbing Systems</span> 
            <ArrowForward style={{...webStyle.arrowforward}} />          
          </Grid>
          
          <Grid item style={webStyle.navMenuItem} id="navigation" className="currentWay" xs={4} sm={4} md={1}>
            <span> 90 degree UPVC plain Elbow </span>
          </Grid>
         
          
        </Grid>
          <Box style={{}}>
          
              <Box item className="main-photo">
                  <Box  className="mainPhoto-container">
                    <img
                    src={this.dummyImg[this.state.focusedImageIndex]}
                    alt={`Product`}
                    style={webStyle.mainPhoto}
                    id="mainPhoto"
                    />
                   <img src={zoom} alt="zoom" className="mainPhoto__zoom" />
                  </Box>
                  <Box item style={{margin:"20px 0",justifyContent:"space-around" , display:'flex',marginTop:'8px'}} className="notMainPhoto-container">
                   
                    {this.dummyImg.map((item, index)=>{
                      return(
                      
                        <img
                        src={item}
                        alt={`Product`}
                        id="carousel"
                        style={webStyle.notMainPhoto}

                        className="notMainPhoto"
                        onClick={()=>{
                          this.setState({focusedImageIndex: index})
                        }}  
                      />
                      )
                    })} 
                  </Box>
              
              </Box>
              <Box  >
                <Typography style={{...webStyle.documentsText, fontFamily : "Epilo"}}>View Documents</Typography>
                <Box style={{marginTop:'12px'}}>
                <table style={webStyle.documentsTable} cellPadding={0} cellSpacing={0} className="documentsTable">
                  <tr>
                    <td style={webStyle.tableTd}>Technical Data Sheets (TDS)</td>
                      <td style={webStyle.tableTd} className="viewText">
                        <Box style={{ display: 'flex', gap: '4px', justifyContent:'center',alignItems:'center'}}>
                          <img src={view} alt="view" style={{ height:'12px', width:'12px',}}/>
                          <Typography style={webStyle.view}>View</Typography>
                        </Box>

                      </td>
                  </tr>
                  <tr>
                    <td style={webStyle.tableTd}>Brochure</td>
                    <td style={webStyle.tableTd} className="viewText">
                        <Box style={{ display: 'flex', gap: '4px', justifyContent:'center',alignItems:'center' }}>
                          <img src={view} alt="view"  style={{width:'12px', height:'12px'}} />
                          <Typography style={webStyle.view}>View</Typography>
                        </Box>

                      </td>
                  </tr>
                </table>
                </Box>
              </Box>
              
         
          </Box>
          <Box  style={{...webStyle.secondPart}}>
              
                <Box   className="NameFeature">
                  <h2 style={webStyle.h2}>90° UPVC Elbow SOC SCH 80</h2>
                  <Typography style={webStyle.h4}><span style={webStyle.h4Main}>Description:</span> uPVC fitting used to give a 90 turn to a pipeline.</Typography>
                  <Typography style={webStyle.h4}><span style={webStyle.h4Main}>UPID:</span> XXXXXXXXXX</Typography>
                  <Typography style={webStyle.h4}><span style={webStyle.h4Main}>Selected Brand:</span> Astral</Typography>
                  <Box  >
                    <Typography style={webStyle.h3}>Features</Typography>
                    <Box >
                      {[' Brand Name','GSM',' No. of Sheets ',' No of Ream','Ideal For'].map((prod)=> {
                        return(
                          <Box style={{display:'flex', alignItems:'center', gap:'5px'}}>
                          <img src={Done} alt='done' style={{width:'14px', height:'14px'}}/>
                          <Typography className="featureTableText">
                          {`${prod}  :`}
                          </Typography>
                          </Box>
                        )
                      })}
                    </Box>
                  
                  </Box>
                </Box>
              <Box  className="Variations"  style={{marginTop:"24px"}}>
                <Typography className="Variations__mainText">Variations:</Typography>
                <div style={webStyle.subTitle}>Lorem:</div>
                <Box style={{display:'flex', gap:"10px" ,marginTop:'6px'}}>
                    <button style={webStyle.variationButton}>
                      <Typography style={webStyle.fontVariationButton}>
                        Variation 01
                      </Typography>
                    </button>
                    <button style={webStyle.variationButton}>
                      <Typography style={webStyle.fontVariationButton}>
                        Variation 02
                      </Typography>
                    </button>
                    <button style={webStyle.variationButton}>
                      <Typography style={webStyle.fontVariationButton}>
                        Variation 03
                      </Typography>
                    </button>
                    <button style={webStyle.variationButtonActive}>
                      <Typography style={webStyle.fontVariationButton}>
                        Variation 04
                      </Typography>
                    </button>
                </Box>
              </Box>
                <Box style={{ marginTop: '20px' }} >
                  <div style={{ ...webStyle.brandTable, border: '1px solid #DEDFE3', maxHeight: 200, overflow: "auto" }} >
                    <Table  >
                      <TableHead>
                        <TableRow
                          style={{
                            backgroundColor: "#f5f5f5",
                            height: "35px"
                          }}
                        >
                          <TableCell align="center" style={webStyle.brandTableRow} >Brand</TableCell>
                          <TableCell align="center" style={webStyle.brandTableRow}>Product Brand Name</TableCell>
                          <TableCell align="center" style={webStyle.brandTableRow} >Brand SKU</TableCell>
                          <TableCell align="center" style={{ width: '50px' }} ></TableCell>
                          <TableCell align="center" style={{ width: '50px' }}></TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                    </Table>
                    <div style={{ overflow: 'auto', height: '150px' }} className="brandTableBody">
                      <Table  >
                        <TableBody >
                          {dummyBrandTable.map((item, index) => (
                            <TableRow key={index} >
                              <TableCell style={webStyle.brandTableCell} align="center">
                                {item.brand}
                              </TableCell>
                              <TableCell style={webStyle.brandTableCell} align="center" >
                                {item.name}
                              </TableCell>
                              <TableCell style={webStyle.brandTableCell}  >
                                {item.sku}
                              </TableCell>
                              <TableCell style={{ ...webStyle.brandTableCell, width: '50px' }} align="center">
                                <ArrowRight />
                              </TableCell>
                              <TableCell style={{ ...webStyle.brandTableCell, width: '50px' }} align="center">
                                <Checkbox
                                  color="#FF6600"
                                  icon={<span style={{ width: '16px', height: '16px', borderRadius: '4px', border: '1px solid #DEE1E6', display: 'inline-block' }} />}
                                  Check={<span style={{ width: '16px', height: '16px', borderRadius: '4px', border: '1px solid #DEE1E6', backgroundColor: '#000', display: 'inline-block' }} />}
                                />
                              </TableCell>
                            </TableRow>
                          ))}

                        </TableBody>
                      </Table>
                    </div>
                  </div>

                </Box>
          </Box>
       
              <Box style={{width:"378px"}}  >
              <Box style={{border:'1px solid #DEDFE3' , borderRadius:'12px' , padding:"23px 23px" }}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', gap: '10px',  }}>

                <Typography style={webStyle.getQoute}>Get a Quote</Typography>
                <button style={webStyle.quoteButton}>
                  <img src={InstantIcon} alt="InstantIcon" />
                  Instant Quote
                </button>
              </Box>
              <Typography style={webStyle.orderQuantityText}>Order Quantity</Typography>
                    <Box >
                      <Box container direction="column" justifyContent="space-evenly" style={webStyle.quantity}>
                  <Box style={{
                    display: 'flex',
                    alignItems: "center"
                  }}>
                          <input style={webStyle.quantityInput} placeholder="Quantity"></input>
                         <Box style={{display:"Flex", marginTop:"8px"}}>
                         <Typography style={webStyle.quantityNomer}>No.</Typography>
                         <img src={Downarrow} alt="Downarrow" />
                         </Box>
                        </Box>
                        <Typography style={webStyle.h4ConvertMain}>Unit Conversion - <span style={webStyle.h4Convert}>1 bag = 20 kg</span> </Typography>
                        <Box style={{display:'flex',alignItems:"center", justifyContent:"flex-end" , gap:'4px'}}>
                          <button style={webStyle.addQuotation}>
                          <img src={buttonIocn} alt="buttonIocn" />
                         Add for Quotation
                          </button>
                        </Box>
                      </Box>
                      <Box  style={webStyle.quantity}>
                       
                          <Box style={{display:'flex', alignItems:'start', gap:'14px'}}  >
                            <img src={parcel} style={{height:"16px" , width:'16px', marginTop:'6px'}} />
                            <Box >
                              <Typography style={webStyle.quoteinfo1}> MOQ</Typography>
                              <Typography style={webStyle.quoteInfoAdd}>20 No.s</Typography>
                            </Box>
                          </Box>
                      
                  <Box  >
                    <Box style={{ display: 'flex', alignItems: 'start', gap: '14px', marginTop: '4px' }} >
                      <img src={lead} alt="lead" style={{ height: "16px", width: '16px', marginTop: '6px' }} />
                      <div style={webStyle.quoteInfo}>
                        <Typography style={webStyle.quoteinfo1}
                        >Lead Time</Typography>
                        <Typography style={webStyle.quoteInfoAdd}>Quote Required</Typography>
                      </div>
                    </Box>
                        </Box>
                        <Box  >
                          <Box style={{...webStyle.quoteItem,display: 'flex' ,alignItems: 'start', gap: '14px'}}>
                        <img src={Regional} alt="Regional" style={{ height: "16px", width: '16px', marginTop: '6px' }}/>
                            <div style={webStyle.quoteInfo}>
                              <Typography style={webStyle.quoteinfo1}>Regional Availabililty</Typography>
                              <Typography style={webStyle.quoteInfoAdd}>Available for your Project</Typography>
                            </div>
                          </Box>
                        </Box>
                        <Box  >
                          <Box style={{...webStyle.quoteItem,display: 'flex' ,alignItems: 'start', gap: '14px'}}>
                        <img src={transport} alt="transport" style={{ height: "16px", width: '16px', marginTop: '6px' }} />

                            <div style={webStyle.quoteInfo}>
                              <Typography style={webStyle.quoteinfo1}>Transportation</Typography >
                              <Typography  style={webStyle.quoteInfoAdd}>Quote Required</Typography >
                            </div>
                          </Box>
                        </Box>
                      </Box>

                    </Box>
                    </Box>
                    <Box style={webStyle.request} >
                <Typography className="requestSample">Request for a Sample</Typography>
                <button style={{...webStyle.requestButton, textTransform:'none'}}>
                  Request Sample
                </button>
                <Typography className="noteText"><span className="noteTextMain">Note:</span> Sampling not available for this product.</Typography>
              </Box>
              </Box>
              
       
       
          </Box>
        </Box>
          
               <Box container style={{...webStyle.description, overflowX:'auto'}} > 
               
            <Box style={{ display: 'flex', justifyContent: 'space-between' }} container direction="row" alignItems="center" justifyContent="center">
              {this.buttons.map((button) => (
                <React.Fragment key={button.id}>
                  <button
                    id={`button-${button.id}`}
                    style={this.getButtonStyle(button.id)}
                    onClick={() => this.setState({pageDescription: button.id})}
                  >
                    {button.label}
                  </button>
                  <div style={webStyle.separator}></div>
                </React.Fragment>
              ))}
            </Box>
                <Box >
                  <Grid container direction="column" >
                  {this.state.pageDescription === 1 ? 
                  <Box style={{paddingTop:'25px'}}>
                    <Typography   className="mainTextDescription">The Bosch 1300W Professional Router is a powerful and versatile tool that can handle a variety of routing tasks. With its 1300 watt</Typography> 
                   
                    <Typography className="seccondaryTextDescription">motor, it can easily handle even the most challenging routing jobs</Typography>
                    
                    <Typography className="mainTextDescription">Power 1300W,Collet and bit size [6mm,8mm,1/4 inch],Plunge length 55 mm,Weight 3.5 kg</Typography> 
                   
                    <Typography className="seccondaryTextDescription" style={{marginTop :" 14px"}}>
                      Quick carbon brush change, constant Speed: maximum cutting, Lock on and lock off switch, Overload protection and Restart protection,<br />
                      Flexible options for fast and easy bit changes, 2.5m long cord<br />
                      Warranty 6 Months from the date of invoice (provide invoice copy to claim warranty)<br />
                      Power 1300W, Collet and bit size [6mm, 8mm,/4 inch], Plunge length 55 mm, Weight 3.5 kg
                    </Typography>
                  </Box>
                  : ""}
                  {this.state.pageDescription === 2 ? 
                
                 <Box style={{height:"1520px"}}>
                   <Box style={{display:'flex', justifyContent:'space-evenly', gap:'30px'}}>
                    <Box  style={{ flex:'1'}}>
                      <Typography style={{}} className="specificationMainText">
                        Features
                      </Typography>
                      <Box style={{ border: '1px solid #DEDFE3', borderRadius:'12px', overflow: 'hidden'}}>
                        {dummySpec.map((item, index) => (
                          <Box style={{ display: 'flex', backgroundColor: this.getRowBackgroundColor(index), height: '52px' }}>
                            <Typography style={{ ...webStyle.specKey}}>{item.key}</Typography>
                            <Typography style={webStyle.specValue}>{item.value}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box style={{ flex:'1'}} >
                      <Typography className="specificationMainText">
                        General Properties
                      </Typography>
                      <Box style={{ border: '1px solid #DEDFE3', borderRadius: '12px',  overflow: 'hidden' }}>
                        {dummySpec1.map((item, index) => (
                          <Box style={{ display: 'flex', backgroundColor:this.getRowBackgroundColor(index), height: '52px' }}>
                            <Typography style={{ ...webStyle.specKey }}>{item.key}</Typography>
                            <Typography style={webStyle.specValue}>{item.value}</Typography>
                          </Box>
                        ))}
                      </Box>
                   
                    </Box>
                    </Box> <Box style={{display:'flex', justifyContent:'space-evenly', gap:'30px'}}>
                    <Box  style={{ flex:'1'}}>
                      <Typography style={{}} className="specificationMainText">
                      Physical Properties
                      </Typography>
                      <Box style={{ border: '1px solid #DEDFE3', borderRadius:'12px', overflow: 'hidden'}}>
                        {Physical.map((item, index) => (
                          <Box style={{ display: 'flex', backgroundColor: this.getRowBackgroundColor(index), height: '52px' }}>
                            <Typography style={{ ...webStyle.specKey}}>{item.key}</Typography>
                            <Typography style={webStyle.specValue}>{item.value}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box style={{ flex:'1'}} >
                      <Typography className="specificationMainText">
                      Mechanical Properties
                      </Typography>
                      <Box style={{ border: '1px solid #DEDFE3', borderRadius: '12px' ,overflow: 'hidden' }}>
                        {Mechanical.map((item, index) => (
                          <Box style={{ display: 'flex', backgroundColor: this.getRowBackgroundColor(index), height: '52px' }}>
                            <Typography style={{ ...webStyle.specKey }}>{item.key}</Typography>
                            <Typography style={webStyle.specValue}>{item.value}</Typography>
                          </Box>
                        ))}
                      </Box>
                   
                    </Box>
                    </Box>
                 </Box>
                  : ""} 
                  {this.state.pageDescription === 5 ? 
                   <Box mt={4}>
                   <Box 
                     sx={{
                       display: 'flex',
                       flexWrap: 'wrap',
                       justifyContent: 'space-between',
                       gap: 2, 
                     }}
                   >
                     {[0, 1].map((_, idx) => (
                       <Box
                         key={idx}
                         sx={{
                           marginBottom: 2, 
                           width : "49%"
                         }}
                       >
                         <Box
                           sx={{
                             border: '1px solid #DEDFE3',
                             borderRadius: '12px',
                             overflow: 'hidden',
                           }}
                         >
                           {dummyCertificate.map((item, index) => (
                             <Box
                               key={index}
                               sx={{
                                 display: 'flex',
                                 justifyContent: 'space-between',
                                 alignItems: 'center',
                                 padding: '0 16px',
                                 height: '52px',
                                 backgroundColor: this.getRowBackgroundColor(index),
                                 fontSize: '14px',
                               }}
                             >
                               <Box sx={{ flex: 1 }} style={{...webStyle.Certifiy}}>{item}</Box>
                               <Box sx={{ padding: '0 8px' }}>
                                 <img src={view} alt="view" style={{ width: '24px', height: '24px' }} />
                               </Box>
                             </Box>
                           ))}
                         </Box>
                       </Box>
                     ))}
                   </Box>
                 </Box>
                  : ""}                  
                  </Grid>
                </Box>
          </Box>
        </ProductBlock>
 
 <Box
      style={{
        display: 'flex',
        flexWrap : "wrap",
        justifyContent: 'space-between',
        padding:"28px 58px 20px 58px"
      }}
    >
      <Box style={{width:"169px"}}>
        <Typography variant="h6" style={{...webStyle.Foothead}}>Services</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' , marginTop : "17px"}}>How it works</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block',marginTop : "15px" }}>Work with us</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block' ,marginTop : "15px"}}>About Us</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block', marginTop : "15px" }}>Contact Us</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block' ,marginTop : "15px"}}>Bulk Order</Typography>
      </Box>
      <Box style={{width:"169px", height: "192px"}}>
        <Typography variant="h6" style={{...webStyle.Foothead}}>Top Categories</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block', marginTop : "17px" }}>Power and Tools</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block' }}>Electrical</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block' }}>Plywood and Laminates</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Safety</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Office Stationary</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Hardware</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Lighting and Fans</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Tiles</Typography>
      </Box>

      <Box>
        <Typography variant="h6" style={{...webStyle.Foothead}}>Cities</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block', marginTop : "17px" }}>Lancaster</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block', marginTop:"3px" }}>Castle Rock</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block', marginTop:"3px" }}>Burleson</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block' , marginTop:"3px"}}>Montclair</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block' , marginTop:"3px"}}>Albuquerque</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block', marginTop:"3px" }}>Appleton</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block' , marginTop:"3px"}}>Prescott Valley</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block' , marginTop:"3px"}}>Milford</Typography>

      </Box>

      <Box style={{height: "120px",width:"169px"}}>
        <Typography variant="h6" style={{...webStyle.Foothead}}>Top Categories</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, marginTop : "17px" }}>Power and Tools</Typography>
        <Typography  style={{display: 'block',...webStyle.FooterLink,  }}>Electrical</Typography>
        <Typography  style={{display: 'block',...webStyle.FooterLink,  }}>Plywood and Laminates</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Safety</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Office Stationary</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Hardware</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Lighting and Fans</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Tiles</Typography>
      </Box>

          <Box style={{  padding: "17px 34px 0px 0px", marginLeft: "80px" }}>
            <Typography variant="h6" style={{ ...webStyle.Foothead, marginTop: "17px" }}>Contact us</Typography>
            <Box style={{ display: 'flex', marginTop:"18px" }}><img src={location} alt="location" style={{ width: "20px", height: "20px" , margin: "auto 0px"}} /> <Typography style={{ ...webStyle.address,width: "206px" }}>Abc-123 Company Addressxyz
              Lorem ipsum dolor sit ame.</Typography></Box>
            <Box style={{ display: 'flex', marginTop:"20px" }}><img src={callIcon} alt="location" style={{ width: "20px", height: "20px" }} /> <Typography style={{  ...webStyle.address,width: "80px" }}>123456 598</Typography></Box>
            <Box style={{  display: 'flex', marginTop:"20px" }}><img src={mainIcon} alt="location" style={{ width: "20px", height: "20px" }} /> <Typography style={{  ...webStyle.address,width: "156px" }}>contact@company.com</Typography></Box>
            <Box>
            <Typography style={{...webStyle.copyRight}}>Copyrights 2023.All rights reserved</Typography>
          </Box>
          </Box>
         
    </Box>
   
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  certificate:{fontSize:'16px', fontWeight:500, fontFamily:'Inter, sans-serif', lineHeight:'20px', padding:'20px 10px 20px 20px'},
  view:{fontSize:'12px', fontWeight:400, fontFamily:'Inter, sans-serif', lineHeight:'20px'},
  subTitle:{fontWeight:400 , fontSize:'14px', lineHeight:'26px' ,     fontFamily: 'Inter, sans-serif',  },
  quoteinfo1:{fontWeight:700 , fontSize:'12px', lineHeight:'26px' ,     fontFamily: 'Inter, sans-serif',  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  globalContainer:{
    marginTop:"20px",
  },
  tableEye:{
    width:"12px",
    height:"12px"
  },
  documentsTable: {
    borderRadius:"6px",
    border:"1px solid #DEDFE3",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 700,
    overflow:"hidden",
  },
  tableTd:{
    padding:"10px",
    border:"1px solid #DEDFE3",
    textAlign:"center",
    alignItems:'center',
    justifyContent:"center"
  },
  tableChild:{
    padding:"19px",
    borderTop:"1px solid",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  arrowforward:{
    height : "16px",
    width : "16px"
  },
  tableChildLeftUp: {
    padding:"19px",
    textAlign:"center",
  },
  tableChildRightUp: {
    padding:"19px",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildLeftDown: {
    padding:"19px",
    borderTop:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildRightDown: {
    padding:"19px",
    borderTop:"1px solid",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  firstCell: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px"
  },
  lastCell: {
    border: "1px solid",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px"
  },
  copyRight:{
    fontFamily : "Inter, sans-serif",
    fontSize : "15px",
    fontWeight : 400,
    color : "#BDC1CA",
    lineHeight : "24px",
    marginTop : "56px"
  },
  variationButton: {
    cursor:"pointer",
    height:"35px",
    padding: "8px 12px",
    border: '1px solid',
    borderColor: "#F0F0F0",
    borderRadius:"9px",
    backgroundColor: "#FFFFFF",
    color:"#1E293B",
    "&:hover": {
      backgroundColor: "#EE6920",
      color: "#FFFFFF"
    },
    fontFamily: 'Inter, sans-serif',
  },
  fontVariationButton:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
  },
  variationButtonActive: {
    height:"35px",
    padding: "8px 12px",
    border: '1px solid',
    borderColor: "transparent",
    borderRadius:"9px",
    backgroundColor: "#FF6600",
    color: "#FFFFFF"
  },
  address:{
    fontFamily : "Inter, sans-serif",
    fontWeight : 400,
    fontSize : "14px",
    marginLeft: "6px",
    color:"#BDC1CA"
  },
  FooterLink:{
    color : "#BDC1CA",
    fontFamily : "Inter, sans-serif",
    fontSize : "12px",
    fontWeight : "400",
    lineHeight : "24px"
  },
  brandTable:{
    border:"1px solid DEDFE3",
    borderRadius:"12px",
    overflow:"hidden",
    height:"225px",
    width : "468px"
  },
  Certifiy:{
    fontSize : "16px",
    fontWeight : 500,
    fontFamily : "Inter, sans-serif"
  },
  brandTableCell:{
    borderTop:"1px solid #DEE1E6",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#171A1F",
   lineHeight:'24px',
   textAlign:'center'
  },
  topnav:{
    fontSize : "12px",
    fontWeight :700,
    fontFamily : "Inter, sans-serif"
  },
  brandTableRow:{
   lineHeight:'20px',
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 500,
    color:"#323743",
    textAlign:'center',
    paddingLeft:'20px'  
  },
  quoteButton:{
    color:"#FFFFFF",
    backgroundColor:"#FF6600",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    borderRadius:"9px",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:'5px',
    borderColor:"transparent",
    height:"32px",
    width: "132px",
    whiteSpace:"nowrap",
    cursor:"pointer"
  },
  quote:{
    border:"1px solid",
    borderRadius:"8px",
    borderColor:"#DEDFE3",
    padding:"20px"
  },
  quantityInput:{
    width:"130px",
    height:"30px",
    backgroundColor:"#F3F4F6",
    borderRadius:"9px",
    alignItems:"center",
    borderColor:"transparent",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    cursor:"pointer",
    color:'#BDC1CA',
    lineHeight:'20px'
  },
  quantity:{
   
  },
  Foothead:{
    fontFamily : "Epilogue, sans-serif",
    fontWeight : 700,
    fontSize : "14px",
    lineHeight : "30px"
  },
  quantityNomer:{
    color:"#FF6600",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    marginLeft:"15px",
    cursor:"pointer",
    lineHeight:'20px',
  },
  addQuotation:{
    color:"#FFFFFF",
    backgroundColor:"#FF6600",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    borderRadius:"9px",
    padding:"10px 8px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around",
    borderColor:"transparent",
    marginTop:"8px",
    cursor:"pointer",
    height : "32px",
    lineHeight:'20px'
  },
  quoteInfo:{
    display: "flex",
    alignContent:"",
    flexDirection:"column",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 700,
    color:"#323743",
    lineHeight:"26px"
  },
  quoteItem:{
    marginTop:"16px"
  },
  request:{
    border:"1px solid",
    borderRadius:"12px",
    borderColor:"#DEDFE3",
    marginTop:"20px",
    padding:"5px 0px 17px 20px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
    backgroundColor : "#F8F9FA"
  },
  requestButton:{
    color:"#FFFFFF",
    backgroundColor:"#969697",
    borderRadius:"9px",
    marginTop:"8px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    borderColor:"transparent",
    padding:"6px 0px",
    height:'32px',
    width:"118px",
    cursor:"pointer"
  },
  description:{
    border: "1px solid",
    borderRadius:"9px",
    marginTop:"60px",
    padding:"19px 24px 30px 24px",
    borderColor:"#DEDFE3",
    marginBottom:"60px",
    height:"auto",
    width : "92%",
    margin: "0px auto"
  },
  descriptionButton__active:{
    borderRadius:"12px",
    backgroundColor:"#FF6600",
    color:"#FFF",
    padding:"11px 43px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
    borderColor:"transparent",
    cursor:"pointer"
  },
  descriptionButton:{
    backgroundColor:"#FFF",
    color:"#FF6600",
    padding:"11px 38px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
    borderColor:"transparent",
    cursor:"pointer"
  },
  separator:{
    height:"22px",
    border:"1px solid",
    borderColor:"#FFEBDD",
  },
  textDescription:{
    marginTop:"25px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "20px",
    fontWeight: 400,
    color:"#334155",
  },
  navMenu:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "11px",
    fontWeight: 700,
    color:"#323743",
  },
  navMenuItem:{
    height:"19px",
    alignItems:"center",
    whiteSpace:"nowrap",
    display:"flex",
    cursor:"pointer"
  },
  notMainPhoto:{
    width:'90px',
    height:'85px',
    borderRadius:'9px',
    border:'1px solid #DEDFE3'
  },
  mainPhoto:{
    width:'386px',
    height:'347px',
    borderRadius:'12px',
    border:'1px solid #DEDFE3',
    backgroundColor:'#FFFFFF'
  },
  documentsText:{
    fontFamily : "Epilogue, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight:"38px",
    color:"#323743",
    marginTop:'5px'
  },
  secondPart:{
    fontFamily: 'Inter, sans-serif',
  },
  h2:{
    fontSize: "24px",
    fontWeight: 700,
    color:"#323743",
    margin:"4px 0px"
  },
  h4:{
    fontSize: "12px",
    fontWeight: 400,
    color:"#323743",
    margin:"5px 0px",
    lineHeight:'22px'
  },
  h4Main:{
    fontWeight: 700,
    fontSize:'12px',
    lineHeight:'22px'
  },
  h3:{
    fontSize: "12px",
    fontWeight: 700,
  },
  featureLabel:{
    fontSize: "16px",
    fontWeight: 500,
    color:"#9095A0"
  },
  getQoute:{
    fontSize: "16px",
    fontWeight: 700,
    color:"#171A1F",
    fontFamily: 'Epilogue, sans-serif',
  },
  orderQuantityText:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 700,
    color:"#424856",
    lineHeight:'20px',
    marginTop:"14px"

  },
  h4Convert:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "11px",
    fontWeight: 400,
    color:"#9095A1",
    margin:"0px",
    lineHeight:"26px"
  },
  h4ConvertMain:{
    color:"#1E293B",
    fontWeight: 700,
    fontSize:'11px',
    lineHeight:'26px'
  },
  quoteInfoAdd:{
    color:"#9095A1",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight:'26px'
  },
  specTable:{
    width:"100%",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 500,
    lineHeight:"20px",
    color:"#666666",
    marginBottom:"20px",
    border:"1px solid #DEDFE3",
    overflow:"hidden",
    borderRadius:"8px",
    marginTop : "20px"
  },
  spec:{
    height:"auto",
   
  },
  specKey:{
    color:"#1E293B",
    fontFamily: 'Inter, sans-serif',
    padding:"20px 10px 20px 20px",
    width:"270px",
    fontSize:'16px',
    lineHeight:'20px',
    fontWeight:500

  },
  specValue:{
    fontSize:'16px',
    lineHeight:'20px',
    fontWeight:500,
    fontFamily: 'Inter, sans-serif',
    height:"50px",
    width:"60%",
    color:"#666666",
    padding:"20px 10px 20px 24px",

  }
};
// Customizable Area End
